import React, { useEffect } from "react";
import Header from "../../Component/Header/Header";
import { Helmet } from "react-helmet";
import Footrer from "../../Component/Footer/Footrer";
import { IoIosArrowForward } from "react-icons/io";
import "./germanl.css";
import { Link } from "react-router-dom";

const GermanL = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="aboutus_header header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | German </title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="background_image22">
        <img src="/Images/warum-studieren-in-germany.webp" alt="image" />
        <div className="bacggroundImgaes_heading_blogPage">
          <p>
            {" "}
            <Link to={"/"}>
              Build Your Career in Germany with{" "}
              <span>
                {" "}
                <IoIosArrowForward /> The Migration School
              </span>{" "}
            </Link>
          </p>
        </div>
      </div>
      <div className="wrapper wrapper_about_us wrapper_ZindexStyle BlogSection_Page">
        <div className="container">
          <div className="jobReddy_programm_Germany">
            <h3>Job Ready Program</h3>
            <p>
              Are you looking to start a rewarding career in Germany? At The
              Migration School, we offer job-readiness programs that empower you
              to secure a job in Germany by mastering the German language.
            </p>
            <p>
              Our specialized training focuses on practical, job-oriented
              classes, ensuring you gain the skills needed to succeed in the
              German job market. Upon successful completion of the B1 German
              language level, we guarantee 100% job placement in Germany.
            </p>
            <div className="german_key_highilights">
              <h4>Program Highlights:</h4>
              <ul>
                <li>
                  <strong>100% Job Placement Guarantee: </strong>Secure
                  employment in Germany's healthcare or hospitality sectors
                  after completing your B1 level.
                </li>
                <li>
                  <strong>Practical Learning Approach: </strong>Hands-on classes
                  focused on real-world skills to boost your employability.
                </li>
                <li>
                  <strong>Career-Focused Curriculum: </strong>Tailored to help
                  you excel in Germany’s healthcare and hospitality industries.
                </li>
              </ul>
              <p>
                Join us today and transform your career. Let The Migration
                School guide you toward a successful future in Germany!
              </p>
            </div>
            <div className="apply_form_buttonDiv">
            <Link to={"/form"}>
              <button className="form_submit_button">Apply</button>
            </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <Footrer />
      </div>
    </>
  );
};

export default GermanL;
