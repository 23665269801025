import React, { useEffect } from "react";
import Header from "../../Component/Header/Header";
import { Helmet } from "react-helmet";
import "./jobSection.css";
import Footrer from "../../Component/Footer/Footrer";
import { Link } from "react-router-dom";

const JobSectionn = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="aboutus_header header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | Job Vacancies</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="background_image22">
        <img src="/Images/job-bg-section.jpg" alt="image" />
        <div className="bacggroundImgaes_heading">
          <h5>
            Looking for a Job You <br /> Deserve?
          </h5>
        </div>
      </div>

      <div className="job_vacancies_cards wrapper_ZindexStyle ">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card job_Vacancies_Cards_images_style">
                <img src="/Images/Greece-WorkPermit.avif" alt="" />
                <h6>Greece</h6>
                <h5>AirPort Ground Staff</h5>
                <div className="main_jobCards">
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Salary:</strong> 950 EURO + OT
                      </li>
                      <li>
                        <strong>Skill: </strong> English skill more than average
                      </li>
                      <li>
                        <strong>Accommodation: </strong> Provided by Company
                      </li>
                      <li>
                        <strong>Visa Fee:</strong> Included
                      </li>
                      <li>
                        <strong>Flight Ticket:</strong> Included
                      </li>
                    </ul>
                  </div>
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Processing Time:</strong> 4 TO 6 Months
                      </li>
                      <li>
                        <strong>Interview:</strong> Required
                      </li>
                      <li>
                        <strong>Contract:</strong> 5 Year
                      </li>
                      <li>
                        <strong className="slovakia_notes">Note:</strong> Only
                        Male Candidates
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="apply_form_buttonDiv">
                <Link to={'/form'}>
                  <button className="form_submit_button" >Apply</button>
                </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card job_Vacancies_Cards_images_style">
                <img src="/Images/cyprus3_workpermit.webp" alt="" />
                <h6>Cyprus</h6>
                <h5>Welder</h5>
                <div className="main_jobCards">
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Salary:</strong> 1300 Euro
                      </li>
                      <li>
                        <strong>Accommodation: </strong> Provided by Company
                      </li>
                      <li>
                        <strong>Visa Fee:</strong> Not Included
                      </li>
                      <li>
                        <strong>Flight Ticket:</strong> Not Included
                      </li>
                    </ul>
                  </div>
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Processing Time:</strong> 3 to 4 Months
                      </li>
                      <li>
                        <strong>Interview:</strong> Required
                      </li>
                      <li>
                        <strong>Contract:</strong> 2 Year + Renewable
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="apply_form_buttonDiv">
                <Link to={'/form'}>
                  <button className="form_submit_button" >Apply</button>
                </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card job_Vacancies_Cards_images_style">
                <img src="/Images/cyprus-tg-workPermit.png" alt="" />
                <h6>Cyprus</h6>
                <h5>Customer Care Representative</h5>
                <div className="main_jobCards">
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Salary:</strong> € 900 - € 1100
                      </li>
                      <li>
                        <strong>Transportation: </strong> Provided by Company
                      </li>
                      <li>
                        <strong>Accommodation: </strong> Provided by Company
                      </li>
                      <li>
                        <strong>Visa Fee:</strong> Not Included
                      </li>
                      <li>
                        <strong>Flight Ticket:</strong> Not Included
                      </li>
                    </ul>
                  </div>
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Processing Time:</strong> 4 to 6 Months
                      </li>
                      <li>
                        <strong>Interview:</strong> Required
                      </li>
                      <li>
                        <strong>Contract:</strong> 2 Year
                      </li>
                      <li>
                        <strong>Experience:</strong> Customer service,
                        International Call center
                      </li>
                      <li>
                        <strong>Age: </strong> 20 to 30 Yr
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="apply_form_buttonDiv">
                <Link to={'/form'}>
                  <button className="form_submit_button" >Apply</button>
                </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card job_Vacancies_Cards_images_style">
                <img src="/Images/Overview-Berlin-germany.webp" alt="" />
                <h6>Germany</h6>
                <h5>Healthcare Industry</h5>
                <div className="main_jobCards">
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Salary: </strong> € 1100 - € 1300
                      </li>
                      <li>
                        <strong>Food: </strong> Provided by Company
                      </li>
                      <li>
                        <strong>Accommodation: </strong> Provided by Company
                      </li>
                      <li>
                        <strong>Visa Fee:</strong> Not Included
                      </li>
                      <li>
                        <strong>Flight Ticket:</strong> Not Included
                      </li>
                    </ul>
                  </div>
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Processing Time:</strong> 2 to 3 Months
                      </li>
                      <li>
                        <strong>Interview:</strong> Required
                      </li>
                      <li>
                        <strong>Contract:</strong> 3 Year
                      </li>
                      <li>
                        <strong>Age: </strong> 21 to 35 Yr
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="apply_form_buttonDiv">
                <Link to={'/form'}>
                  <button className="form_submit_button" >Apply</button>
                </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card job_Vacancies_Cards_images_style">
                <img
                  src="/Images/television-tower-berlin-germany-workPermit.jpeg"
                  alt=""
                />
                <h6>Germany</h6>
                <h5>Hospitality</h5>
                <div className="main_jobCards">
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Salary:</strong> € 1100 - € 1300
                      </li>
                      <li>
                        <strong>Food: </strong> Provided by Company
                      </li>
                      <li>
                        <strong>Accommodation: </strong> Provided by Company
                      </li>
                      <li>
                        <strong>Visa Fee:</strong> Not Included
                      </li>
                      <li>
                        <strong>Flight Ticket:</strong> Not Included
                      </li>
                    </ul>
                  </div>
                  <div className="jobCards_content">
                    <ul>
                      <li>
                        <strong>Processing Time:</strong> 2 to 3 Months
                      </li>
                      <li>
                        <strong>Interview:</strong> Required
                      </li>
                      <li>
                        <strong>Contract:</strong> 3 Year
                      </li>
                      <li>
                        <strong>Age: </strong> 21 to 35 Yr
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="apply_form_buttonDiv">
                <Link to={'/form'}>
                  <button className="form_submit_button" >Apply</button>
                </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footrer />
      </div>
    </>
  );
};

export default JobSectionn;
