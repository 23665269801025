import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from './Pages/AboutUs/AboutUs.jsx';
import Courses from './Pages/Courses/Courses.jsx';
import Home from './Pages/Home/Home.jsx';
import ContactUs from './Pages/ContactUs/ContactUs.jsx';
import TermAndConditions from './Pages/PolicyPages/TermAndConditions.jsx';
import PrivacyPolicy from './Pages/PolicyPages/PrivacyPolicy.jsx';
import RefundPolicy from './Pages/PolicyPages/RefundPolicy.jsx';
import ShippingPolicy from './Pages/PolicyPages/ShippingPolicy.jsx';
import PaymentSuccess from './Pages/Courses/PaymentSuccess.jsx';
import PaymentFail from './Pages/Courses/PaymentFail.jsx';
import Services from './Pages/Services/Services.jsx';
import JobSectionn from './Pages/job-Vacancies/JobSectionn.jsx';
import GermanL from './Pages/GermanLanguage/GermanL.jsx';
import FrenchL from './Pages/FrenchLanguage/FrenchL.jsx';
import ApplyForm from './Pages/Form/Form.jsx';


function App() {
  return (
    <>
       <BrowserRouter>
      <Routes>        
          <Route path="/" element={<Home />} />
          <Route path="/Courses" element={  <Courses />} />
          <Route path="/About-Us" element={<AboutUs />} />
          <Route path="/Contact-Us" element={<ContactUs/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/job-vacancies" element={<JobSectionn/>} />
          <Route path="/german" element={<GermanL/>} />
          <Route path="/french" element={<FrenchL/>} />
          <Route path="/form" element={<ApplyForm/>} />
          {/* <Route path="/Pay-Now" element={<Payment/>} /> */}
          <Route path="/Term-and-conditions" element={<TermAndConditions/>} />
          <Route path="/Privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/Refund-policy" element={<RefundPolicy/>} />
          <Route path="/shiping-policy" element={<ShippingPolicy/>} />
          <Route path="/paymentsuccess" element={<PaymentSuccess/>} />
          <Route path="/paymentFail" element={<PaymentFail/>} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;



