import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "./from.css";
import { useNavigate } from "react-router-dom";

const ApplyForm = () => {
    const navigate = useNavigate();
  const [fForm, setFForm] = useState({
    fName: "",
    lName: "",
    cNomber: "",
    email: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFForm(() => ({
      ...fForm,
      [name]: value,
    }));
  };

//   console.log("fForm", fForm);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFForm((prevVisa) => ({
      ...prevVisa,
      file: file,
    }));
  };

const handleSubmitData = async (e)=>{
    e.preventDefault();    

    const formData = new FormData();
    formData.append("fName", fForm.fName);
    formData.append("lName", fForm.lName);
    formData.append("cNomber", fForm.cNomber);
    formData.append("email", fForm.email);
    formData.append("files99", fForm.file);

    try{
        const response = await fetch("https://backend-visa2.vercel.app/tmsApi/upload/dataGermanWorkOther",
            {
                method:"POST",
                body: formData
            }
        );
        if(!response.ok){
            throw new Error("Network Response was not ok");            
        }
        alert("Form Submitted successfully.")
        navigate("/")
        setFForm({
            fName: "",
            lName: "",
            cNomber: "",
            email: "",
            file: null,
        });
    } catch(error){
        console.log("There was an error",error);
    }  
}

  return (
    <>
      <div className="wrapper">
        <div className="container">
          <div className="main_wrapper_form">
            <div className="apply_form">
              <h4>Apply Here</h4>
              <div className="form_name">
                <input
                  name="fName"
                  value={fForm.fName}
                  onChange={handleChange}
                  type="text"
                  placeholder=" First Name"
                />
                <input
                  type="text"
                  name="lName"
                  value={fForm.lName}
                  onChange={handleChange}
                  placeholder="Last Name"
                />
              </div>
              <div className="form_name">
                <input
                  name="cNomber"
                  value={fForm.cNomber}
                  onChange={handleChange}
                  type="text"
                  placeholder="Mobile No."
                />
                <input
                  name="email"
                  value={fForm.email}
                  onChange={handleChange}
                  type="email"
                  placeholder="Email"
                />
              </div>
              <div className="resume_form">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload Resume</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
              </div>
              <div className="apply_form_buttonDiv">
                <button className="form_submit_button"onClick={handleSubmitData} >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyForm;
